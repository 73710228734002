<template>
  <div>
    <p :class="$style['discount-title']">
      {{ t("label") }} ({{ t("salesOrg") }} {{ salesOrg }})
    </p>
    <table>
      <tr>
        <td>
        <bu-atom-radio-button
              ref="discountByCustomerNumber"
              :value="discountBy.customerNumber"
              :selected-value="selectedNumber"
              :modifier="['variant']"
              @input="(arg) => {
                selectedNumber = arg;
                useCustomerNr = true;
                useSegment = false;
              }"
          >
          {{ t("customerNr") }}
          </bu-atom-radio-button>
          <bu-atom-radio-button
              ref="discountBySegment"
              :value="discountBy.segment"
              :selected-value="selectedNumber"
              :modifier="['variant']"
              @input="(arg) => {
                selectedNumber = arg;
                useCustomerNr = false;
                useSegment = true;
              }"
          >
          {{ t("segment") }}
          <BuAtomIconPopup ref="infoDiscountBy" style="margin-left: 10px;" icon="info">
        {{ t("infoDiscountBy") }}
      </BuAtomIconPopup>
          </bu-atom-radio-button>
          
        </td>
      </tr>
      <br>
      <tr>
        <td v-if="useCustomerNr" >
          <p :class="$style['input-caption']">{{ t("customerNr") }}</p>
        </td>
        <td v-if="useSegment">
          <p :class="$style['input-caption']">{{ t("segment") }}</p>
        </td>
        <td>
          <p :class="$style['input-caption']">{{ t("quantity") }}</p>
        </td>
      </tr>
      <tr>
        <td v-if="useCustomerNr" :class="$style['customer-nr-input']">
          <BuAtomInput
            ref="customerNr"
            v-model:value="customerNr"
            name="customerNr"
            label="customerNr"
            :modifier="['small']"
            :placeholder="t('customerNr')"
            :max-length="customerNrInputMaxLength"
          />
        </td>
        <td v-if="useSegment" :class="$style['customer-nr-input']">
          <bu-molecule-form-column class="width590">
            <buAtomSelect
            id="segment"
            ref="segment"
            v-model:value="segment"     
            :label="t('segment')"
            :options="getSegmentsOptions()"
            />
        </bu-molecule-form-column>
        </td>
        <td :class="$style['quantity-input']">
          <BuMoleculeNumberInput
            ref="quantity"
            v-model:value="quantity"
            label="t('quantity')"
            :modifier="['big']"
            :class="$style['z-hack']"
            @update:value="launchMiner"
          />
        </td>
      </tr>
    </table>

    <BuAtomButton
      ref="launchMiner"
      :modifier="['big', 'secondary', 'full-width']"
      icon="search"
      :disabled="(!useCustomerNr && (typeof segment=== 'undefined' || segment.length === 0))||(useCustomerNr && (typeof customerNr=== 'undefined' || customerNr.length === 0))"
      @button-click="launchMiner"
    >
      <span>{{ t("submit") }}</span>
    </BuAtomButton>
    <div :class="$style['output-box']">
      <bu-molecule-content-box
        v-if="showDiscountContent"
        :headline="false"
        :modifier="['small']"
        :popup="false"
      >
        <div>
          <table :class="$style['output-table']">
            <tr v-if="error.length == 0">
              <td :class="$style['output-text']">
                <p>{{ t("target") }}:</p>
              </td>
              <td :class="[$style['output-text'], $style['desc-width']]">
                <p>{{ target }}</p>
              </td>
            </tr>
            <tr v-if="error.length == 0">
              <td :class="$style['output-text']">
                <p>{{ t("standard") }}:</p>
              </td>
              <td :class="[$style['output-text'], $style['desc-width']]">
                <p>{{ standard }}</p>
              </td>
            </tr>
            <tr v-if="error.length == 0">
              <td :class="$style['output-text']">
                <p>{{ t("floor") }}:</p>
              </td>
              <td :class="[$style['output-text'], $style['desc-width']]">
                <p>{{ floor }}</p>
              </td>
            </tr>
            <tr v-if="error.length > 0">
              <td colspan="2" :class="$style['center']">
                <p>{{ error }}</p>
              </td>
            </tr>
          </table>
        </div>

        <BuAtomLoading v-if="isLoading" :modifier="['input']" />
      </bu-molecule-content-box>
    </div>
  </div>
</template>

<script>
import { getMiner } from "../../../api";

export default {
  name: "MoleculeDiscountRange",

  props: {
    sku: {
      type: String,
      default: "",
    },
    salesOrg: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      target: " ",
      standard: " ",
      floor: " ",
      error: " ",
      quantity: 1,
      customerNr: this.$store.state.global.minerCustomer,
      customerNrInputMaxLength: 10,
      showDiscountContent: false,
      isLoading: false,
      selectedNumber: "customerNumber",
      discountBy: {
        customerNumber: "customerNumber",
        segment: "segment"
      },
      useCustomerNr: true,
      useSegment: false,
      segment: this.$store.state.global.minerSegment,
    };
  },

  watch: {
    customerNr() {
      if (this.customerNr.length !== this.customerNrInputMaxLength) {
        this.showDiscountContent = false;
      }
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.organisms.details.product.miner";
      return this.$t(`${prefix}.${key}`, options);
    },
    async launchMiner() {
      this.showDiscountContent = true;
      this.isLoading = true;
      this.error = " ";
      let response = {};

      if(this.useCustomerNr) {
        // Check whether the customerNr has 10 digits and if not then add zeros in front of the current number until a length of 10 is reached

        if (this.customerNr.length !== this.customerNrInputMaxLength) {
          this.customerNr = this.customerNr.padStart(
            this.customerNrInputMaxLength,
            "0"
          );
        }   
        this.$store.commit("global/setCustomer", this.customerNr);
        this.segment = "";
   
      } else if(this.useSegment) {
        this.$store.commit("global/setSegment", this.segment);
        this.customerNr = "";
      }
      
      //Working test data For testing purposes
      //await getMiner("00001140", 1, "57000848", "5700",)
      
      await getMiner(
        ("00000000" + this.sku).slice(-8),
        this.quantity,
        this.customerNr,
        this.segment,
        this.salesOrg
      )
        .then((data) => {
          response = data;

          // ++++++++++++++++++++++ TEST ++++++++++++++++++++++
          // if (this.quantity < 5) {
          //   response = {
          //     data: {
          //       sku_target: "11.98",
          //       sku_standard: "22.02",
          //       sku_floor: "29.2",
          //       type_target: "21.75",
          //       type_standard: "32.16",
          //       type_floor: "38.49"
          //     }
          //   }
          // }
          // else if (this.quantity >= 5 && this.quantity < 10) {
          //   response = {
          //     data: {
          //       sku_target: "22.98",
          //       sku_standard: "44.02",
          //       sku_floor: "34.2",
          //       type_target: "45.75",
          //       type_standard: "38.16",
          //       type_floor: "50.49"
          //     }
          //   }
          // }
          // else if (this.quantity >= 10) {
          //   response = {
          //     data: {
          //       sku_target: "34.98",
          //       sku_standard: "50.02",
          //       sku_floor: "79.2",
          //       type_target: "80.75",
          //       type_standard: "82.16",
          //       type_floor: "89.49"
          //     }
          //   }
          // }
          // ++++++++++++++++++++++ TEST ++++++++++++++++++++++

          this.displayDiscounts(response.data, response.error);
        })
        .catch((error) => {
          this.error = error.name + ": " + error.message;
        });
      this.isLoading = false;
    },
    displayDiscounts(discount, error) {
      if (error) {
        this.error = this.t("error");
      } else if (discount.sku_floor && discount.sku_floor.length > 0) {
        this.floor = this.formatDiscount(discount.sku_floor);
        this.standard = this.formatDiscount(discount.sku_standard);
        this.target = this.formatDiscount(discount.sku_target);
        this.error = "";
        //type fallback
      } else if (discount.type_floor && discount.type_floor.length > 0) {
        this.floor = this.formatDiscount(discount.type_floor);
        this.standard = this.formatDiscount(discount.type_standard);
        this.target = this.formatDiscount(discount.type_target);
        this.error = "";
      } else {
        this.error = this.t("failed");
      }
    },
    formatDiscount(stringVal) {
      return parseFloat(stringVal).toFixed(2) + " %";
    },
    getSegmentsOptions() {
      let segmentsOptions = [];
      let segmentList = this.$store.state.global.minerSegmentList;

      for(let segment in segmentList) {
        segmentsOptions.push({
          key: segmentList[segment],
          value: segmentList[segment]
        })
      }
      return segmentsOptions; 
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.discount-title {
  font-family: "Helvetica Neue Extended", Helvetica, sans-serif;
  font-size: 14px;
  padding-bottom: 7px;
  padding-top: 15px;
}

.input-caption {
  font-size: 12px;
  padding-bottom: 3px;
}

.customer-nr-input {
  width: 66%;
  padding-right: 10px;
  padding-bottom: 10px;
}

.quantity-input {
  padding-bottom: 10px;
}

.output-box {
  margin-top: 10px;
}

.output-table {
  width: 100%;
  max-width: 445px;
  font-size: 16px;
}

.output-text {
  text-align: right;
  white-space: nowrap;
}

.desc-width {
  width: 70px;
}

.center {
  text-align: center;
}

/* Hack to bypass effect of number input having z-index: 1*/
.z-hack {
  z-index: 0;
  position: relative;
}
</style>
