const state = () => ({
  /**
   * Product category facet filters
   * @type {Array}
   */
  filters: {},

  /**
   * Show complete result set
   * or limit visiblity manually
   * @type {Boolean}
   */
  showAllResults: false,

  /**
   * Additional search facets
   * @type {Object}
   */
  additionalFacets: {
    /**
     * Search term, i.e. what the user has entered into the search input
     * @type {String}
     */
    searchTerm: "",

    /**
     * Search term type, one of "any", "type", "ident", "devicekey", "keyConverter"
     * @type {String}
     */
    searchTermType: "any",

    /**
     * Product category group code, i.e. 1_2. May be empty
     * @type {String}
     */
    groupCode: "",

    /**
     * Optional display of inactive products
     * @type {Boolean}
     */
    showInactive: false,
  },

  /**
   * Whether the next deletion call to the setFilter mutation should clear the searchTerm
   * @type {Boolean}
   */
  reverseSearchMode: false,

  /**
   * Order of explicitely sorted data columns.
   * Keys: Column ID, Values: either "asc" or "desc"
   * @example
   *   { "sku": "asc" }
   * @type {Object}
   */
  sortBy: {},

  /**
   * Results pagination parameters
   * @type {Object}
   */
  pagination: {
    offset: 0,
    limit: 100,
  },

  /**
   * Columns that are visible within the result table
   * @type {string[]}
   */
  visibleColumns: [],

  result: {
    /**
     * When isLoading is true, may contain the origin of the loading event
     * Either "search", "facet" or "result"
     * @type {string}
     */
    actionOrigin: null,

    /**
     * Search API result data
     * @type {Object}
     */
    data: {},

    /**
     * API response error
     * @type {Boolean|Object}
     */
    error: false,

    /**
     * Whether data is currently loading
     * @type {boolean}
     */
    isLoading: false,

    /**
     * When the API last got called
     * @type {Number}
     */
    lastUpdated: -1,
  },
});

export default state;
