/**
 * Get amount of set filter values for each filterGroup
 * @param {*} state - Vuex state
 * @returns {number[]} Array that contains the amount of set filter values for each filterGroup
 */
export const filterCount = (state) =>
  state.result.data.filterGroups
    ? state.result.data.filterGroups.map((fg) =>
        fg.availableFilters.reduce(
          (acc, cur) =>
            acc +
            (state.filters[cur.id]
              ? [].concat(state.filters[cur.id].value).length
              : 0),
          0
        )
      )
    : 0;

/**
 * Get an object containing all availableFilters with their IDs as keys
 * @param {*} state - Vuex state
 * @returns {Object} Object mapping availableFilters IDs to their objects
 */
export const getFlattenedFiltersObj = (state) =>
  // Reduce server-provided filter groups into an object, so for every filter group …
  (state.result.data.filterGroups || []).reduce(
    (acc, cur) => ({
      ...acc,
      // … reduce the availableFilters into an object and append them
      ...cur.availableFilters.reduce(
        (cacc, ccur) => ({
          ...cacc,
          [ccur.id]: ccur,
        }),
        {}
      ),
    }),
    {}
  );

/**
 * Get an object containing all set filters with a title for display
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Object} Object containing set filters
 */
export const getSetFiltersWithTitle = (state, getters) =>
  // Reduce set facet filters into an object, so for every set filter …
  Object.entries(state.filters).reduce((acc, [filterId, filterValue]) => {
    const errors = [];

    // … get the corresponding server-provided filter config
    const filter = getters.getFlattenedFiltersObj[filterId];

    if (typeof filter === "undefined") {
      // collect error causing filters
      errors.push(filterId);

      // eslint-disable-next-line no-console
      console.warn(
        `Cannot get filter config for filter "${filterId}": Filter is no longer available.`
      );

      return { ...acc };
    }

    // … turn the options of the corresponding filter config into an object
    //   mapping from filter option value to filter option object
    const options = filter.data.options.reduce(
      (oacc, ocur) => ({ ...oacc, [ocur.value]: ocur }),
      {}
    );

    const r = {
      ...acc,
      // … create a new object-entry mapping from the filter ID to an array
      //   of objects each with value and title
      [filterId]: [].concat(filterValue.value).map((value) => {
        const option = options[value];

        const title = (() => {
          /* Throw when there is a set option with no corresponding filter
           * in the API response
           */
          if (!option || !{}.hasOwnProperty.call(option, "title")) {
            // collect error causing filters
            errors.push(filterId);

            // eslint-disable-next-line no-console
            console.warn(
              `Cannot get title for option "${value}" from filter "${filterId}": Option is no longer available.`
            );
            // throw new Error(
            //   `Cannot get title for option "${value}" from filter "${filterId}": Option is no longer available.`
            // );
            return;
          }
          const { title } = option;

          // In case your filter type needs special title formatting, do it here
          switch (filter.type) {
            case "operator": {
              return `${filterValue.operator} ${title}`;
            }
            default: {
              return title;
            }
          }
        })();

        return {
          value,
          title,
        };
      }),
    };

    // remove error occuring filter configurations
    if (errors.length) {
      errors.forEach((filter) => {
        delete r[filter];
      });
    }

    return r;
  }, {});

/**
 * Get filterable table headers
 * @param {Object} state - Vuex state
 * @returns {Object[]} Array of table header objects that can be filtered / hidden
 */
export const getFilterableHeaders = (state) =>
  (state.result.data.headers || []).filter(
    (h) => !h.keyAttr && h.value !== "sku"
  );

/**
 * Get visible table headers
 * @param {Object} state - Vuex state
 * @returns {Object[]} Array of table headers that are visible
 */
export const getHeaders = (state) =>
  (state.result.data.headers || []).filter(
    (h) => state.visibleColumns.indexOf(h.value) !== -1
  );

/**
 * Get whether there are results
 * @param {Object} state - Vuex state
 * @returns {Boolean} Whether there are results
 */
export const hasResults = (state) => {
  const { error } = state.result;
  const { pagination } = state.result.data;
  return !error && pagination && pagination.total > 0;
};

/**
 * Get whether there are too many results to display
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Boolean} Whether there are too many results
 */
// export const hasTooManyResults = () => true;
export const hasTooManyResults = (state, getters) => {
  // if pagination limit is disable and all results are shown
  if (state.showAllResults) return false;

  // else pagination limit is enabled and only 100 results are listed
  return getters.hasResults && state.result.data.pagination.total > 100;
};
